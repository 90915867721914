import jwtDecode from 'jwt-decode'

const AuthGuard = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token')
    const { pathname } = window.location

    if (token) {
      const { name, roles } = jwtDecode(token)
      if (name && roles) resolve(true)
    }

    const redirect =
      pathname !== '/'
        ? `/iniciar-sesion?redirect=${pathname}`
        : '/iniciar-sesion'

    reject(new Error(redirect))
  })
}

const ManagersGuard = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token')
    const { roles } = jwtDecode(token)

    const canView = roles.some(
      (role) => role.role === 'manager'
    )

    if (canView) resolve(true)
    reject(new Error('/'))
  })
}

const HeadGuard = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token')
    const { roles } = jwtDecode(token)

    const canView = roles.some(
      (role) => role.role === 'manager' || role.role === 'head'
    )

    if (canView) resolve(true)
    reject(new Error('/'))
  })
}

const WarehouseGuard = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token')
    const { roles } = jwtDecode(token)

    const canView = roles.some(
      (role) =>
        role.role === 'manager' ||
        role.role === 'warehouse' ||
        role.role === 'head'
    )

    if (canView) resolve(true)
    reject(new Error('/'))
  })
}

const OperatorGuard = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token')
    const { roles } = jwtDecode(token)

    const canView = roles.some(
      (role) =>
        role.role === 'manager' ||
        role.role === 'operator' ||
        role.role === 'head'
    )

    if (canView) resolve(true)
    reject(new Error('/'))
  })
}

/*const ProductsGuard = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token')
    const { roles } = jwtDecode(token)

    const canView = roles.some(
      (role) => role.role === 'manager' || role.role === 'warehouse' || role.role === 'head'
    )

    if (canView) resolve(true)
    reject(new Error('/'))
  })
}

const ReferredGuard = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token')
    const { roles } = jwtDecode(token)

    const canView = roles.some(
      (role) => role.role !== 'ejecutivo_telemarketing'
    )

    if (canView) resolve(true)
    reject(new Error('/'))
  })
}*/

/*const AgentGuard = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token')
    const { roles } = jwtDecode(token)

    const canView = roles.some(
      (role) => role.role === 'manager' || role.role === 'vendedor'
    )

    if (canView) resolve(true)
    reject(new Error('/'))
  })
}

const TelemarketingGuard = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token')
    const { roles } = jwtDecode(token)

    const canView = roles.some(
      (role) => role.role === 'ejecutivo_telemarketing'
    )

    if (canView) resolve(true)
    reject(new Error('/'))
  })
}*/

export {
  AuthGuard,
  //ProductsGuard,
  HeadGuard,
  WarehouseGuard,
  ManagersGuard,
  //ReferredGuard,
  //AgentGuard,
  //TelemarketingGuard,
  OperatorGuard,
}
