import axios from 'axios'
const baseURL = process.env.REACT_APP_API
const localToken = localStorage.getItem('token')

if (localToken) {
  axios.defaults.headers.Authorization = `Bearer ${localToken}`
}

const axiosClient = axios.create({
  baseURL,
})

export default axiosClient
