import { lazy } from 'react-router-guard'
import { AuthGuard } from '../helpers'
import PrivateModule from '../modules/private/index'
import PublicModule from '../modules/public'

const Routes = [
  {
    path: '/',
    canActivate: [AuthGuard],
    component: lazy(() => import('../layout')),
    routes: [],
  },
]

Routes.unshift(...PublicModule)
const Layout = Routes.findIndex((route) => route.path === '/')
Routes[Layout].routes.push(...PrivateModule)
Routes[Layout].routes.push({ path: '/:404', redirect: '/no-encontrado' })

export default Routes
