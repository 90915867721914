import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import authReducer from './auth.reducer'
import userReducer from './user.reducer'
import communesReducer from './commune.reducer'
import roleReducer from './role.reducer'
import permissionsReducer from './permissions.reducer'
import categoriesReducer from './categories.reducer'
import productsReducer from './products.reducer'
import contextReducer from './context.reducer'
import officeReducer from './office.reducer'
import warehouseReducer from './warehouse.reducer'
import suppliesReducer from './supplies.reducer'
import suppliesCategoriesReducer from './suppliesCategories.reducer'

const appReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  commune: communesReducer,
  role: roleReducer,
  form: formReducer,
  permission: permissionsReducer,
  categories: categoriesReducer,
  products: productsReducer,
  context: contextReducer,
  offices: officeReducer,
  warehouses: warehouseReducer,
  supplies: suppliesReducer,
  suppliesCategories: suppliesCategoriesReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'AUTH_LOGOUT') state = undefined
  return appReducer(state, action)
}

export default rootReducer
