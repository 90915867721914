const roleTypes = {
  POST_ROLE_REQUEST: "POST_ROLE_REQUEST",
  POST_ROLE_SUCCESS: "POST_ROLE_SUCCESS",
  POST_ROLE_FAILURE: "POST_ROLE_FAILURE",
  GET_ROLE_REQUEST: "GET_ROLE_REQUEST",
  GET_ROLE_FAILURE: "GET_ROLE_FAILURE",
  GET_ROLE_SUCCESS: "GET_ROLE_SUCCESS",
  PATCH_ROLE_REQUEST: "PATCH_ROLE_REQUEST",
  PATCH_ROLE_FAILURE: "PATCH_ROLE_FAILURE",
  PATCH_ROLE_SUCCESS: "PATCH_ROLE_SUCCESS",
};

export { roleTypes };
