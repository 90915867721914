const productsTypes = {
  GET_PRODUCTS_REQUEST: 'GET_PRODUCTS_REQUEST',
  GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_FAILURE: 'GET_PRODUCTS_FAILURE',
  POST_PRODUCTS_REQUEST: 'POST_PRODUCTS_REQUEST',
  POST_PRODUCTSS_SUCCESS: 'POST_PRODUCTS_SUCCESS',
  POST_PRODUCTS_FAILURE: 'POST_PRODUCTS_FAILURE',
  PATCH_PRODUCTS_REQUEST: 'PATCH_PRODUCTS_REQUEST',
  PATCH_PRODUCTS_SUCCESS: 'PATCH_PRODUCTS_SUCCESS',
  PATCH_PRODUCTS_FAILURE: 'PATCH_PRODUCTS_FAILURE',
  PATCH_PRODUCTS_ENABLE_REQUEST: 'PATCH_PRODUCTS_ENABLE_REQUEST',
  PATCH_PRODUCTS_ENABLE_SUCCESS: 'PATCH_PRODUCTS_ENABLE_SUCCESS',
  PATCH_PRODUCTS_ENABLE_FAILURE: 'PATCH_PRODUCTS_ENABLE_FAILURE',
  PATCH_PRODUCTS_DISABLE_REQUEST: 'PATCH_PRODUCTS_DISABLE_REQUEST',
  PATCH_PRODUCTS_DISABLE_SUCCESS: 'PATCH_PRODUCTS_DISABLE_SUCCESS',
  PATCH_PRODUCTS_DISABLE_FAILURE: 'PATCH_PRODUCTS_DISABLE_FAILURE',
  RESET_LIST: 'RESET_LIST',
}

export { productsTypes }
