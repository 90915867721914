import { suppliesTypes as types } from '../types'

const initialState = {
  loading: false,
  error: '',
  all: [],
}

const suppliesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_REQUEST:
      return { ...state, loading: true }
    case types.GET_FAILURE:
      return { ...state, error: action.payload.error, loading: false }
    case types.GET_SUCCESS:
      return { ...state, loading: false, all: action.payload.body }
    case types.POST_REQUEST:
      return { ...state, all: [...state.all], loading: true }
    case types.POST_FAILURE:
      return {
        ...state,
        all: [...state.all],
        error: action.payload.error,
        loading: false,
      }
    case types.POST_SUCCESS:
      return { ...state, loading: false }
    case types.PATCH_REQUEST:
      return { ...state, all: [...state.all], loading: true }
    case types.PATCH_FAILURE:
      return {
        ...state,
        all: [...state.all],
        error: action.payload.error,
        loading: false,
      }
    case types.PATCH_SUCCESS:
      return { ...state, loading: false }
    case types.PATCH_ENABLE_REQUEST:
      return { ...state, all: [...state.all], loading: true }
    case types.PATCH_ENABLE_FAILURE:
      return {
        ...state,
        all: [...state.all],
        error: action.payload.error,
        loading: false,
      }
    case types.PATCH_ENABLE_SUCCESS:
      return { ...state, loading: false }
    case types.PATCH_DISABLE_REQUEST:
      return { ...state, all: [...state.all], loading: true }
    case types.PATCH_DISABLE_FAILURE:
      return {
        ...state,
        all: [...state.all],
        error: action.payload.error,
        loading: false,
      }
    case types.PATCH_DISABLE_SUCCESS:
      return { ...state, loading: false }
    case types.RESET_LIST:
      return {
        loading: false,
        error: '',
        all: [],
      }
    default:
      return state
  }
}

export default suppliesReducer
