const supplyCategoriesTypes = {
  GET_REQUEST: 'GET_SUPPLY_CATEGORIES_REQUEST',
  GET_SUCCESS: 'GET_SUPPLY_CATEGORIES_SUCCESS',
  GET_FAILURE: 'GET_SUPPLY_CATEGORIES_FAILURE',
  POST_REQUEST: 'POST_SUPPLY_CATEGORIES_REQUEST',
  POST_SUCCESS: 'POST_SUPPLY_CATEGORIES_SUCCESS',
  POST_FAILURE: 'POST_SUPPLY_CATEGORIES_FAILURE',
  PATCH_REQUEST: 'PATCH_SUPPLY_CATEGORIES_REQUEST',
  PATCH_SUCCESS: 'PATCH_SUPPLY_CATEGORIES_SUCCESS',
  PATCH_FAILURE: 'PATCH_SUPPLY_CATEGORIES_FAILURE',
}

export { supplyCategoriesTypes }
