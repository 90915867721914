import { lazy } from 'react-router-guard'
import {
  OperatorGuard,
  HeadGuard,
  WarehouseGuard,
  ManagersGuard,
} from 'helpers'

export default [
  {
    name: 'Lista de Clientes',
    path: '/lista-clientes',
    canActivate: [HeadGuard],
    exact: true,
    component: lazy(() => import('../clients')),
  },
  {
    name: 'Lista Clientes Show',
    exact: true,
    path: '/lista-clientes/:id',
    canActivate: [HeadGuard],
    component: lazy(() => import('../clients/show')),
  },
  {
    name: 'Servicios',
    exact: true,
    path: '/servicios',
    canActivate: [HeadGuard],
    component: lazy(() => import('../services')),
  },
  /*{
    name: 'Categorías',
    exact: true,
    path: '/maestro-de-productos/categorias',
    canActivate: [ProductsGuard],
    component: lazy(() => import('../categories')),
  },*/
  {
    name: 'Insumos',
    exact: true,
    path: '/mantenedor-de-insumos',
    canActivate: [HeadGuard],
    component: lazy(() => import('../supplies')),
  },
  {
    name: 'Categorías',
    exact: true,
    path: '/mantenedor-de-insumos/categorias',
    canActivate: [HeadGuard],
    component: lazy(() => import('../categoriesSupplies')),
  },
  {
    name: 'Usuarios y roles',
    exact: true,
    path: '/usuarios',
    canActivate: [ManagersGuard],
    component: lazy(() => import('../UsersRoles')),
  },
  {
    name: 'Listado de Actividades',
    exact: true,
    path: '/calendario-actividades',
    canActivate: [HeadGuard],
    component: lazy(() => import('../calendar')),
  },
  {
    name: 'Calendario de Actividades',
    exact: true,
    path: '/calendario-actividades/calendario',
    canActivate: [HeadGuard],
    component: lazy(() => import('../calendar/agend')),
  },
  {
    name: 'Bodegas',
    exact: true,
    path: '/bodegas',
    canActivate: [WarehouseGuard],
    component: lazy(() => import('../warehouse/index')),
  },
  {
    name: 'Bodegas Show',
    exact: true,
    path: '/bodegas/:id',
    canActivate: [WarehouseGuard],
    component: lazy(() => import('../warehouse/Show')),
  },
  {
    name: 'Mis actividades',
    exact: true,
    path: '/mis-actividades',
    canActivate: [OperatorGuard],
    component: lazy(() => import('../MyAct')),
  },
  {
    name: 'Actividades Show',
    exact: true,
    path: '/mis-actividades/:id',
    canActivate: [OperatorGuard],
    component: lazy(() => import('../MyAct/show')),
  },
  {
    name: 'Servicios Show',
    exact: true,
    path: '/mis-actividades/:id_activity/:id_service',
    canActivate: [OperatorGuard],
    component: lazy(() => import('../MyAct/show/principal')),
  },
  {
    name: 'Certificado',
    exact: true,
    path: '/:id/certificado',
    canActivate: [OperatorGuard],
    component: lazy(() => import('../MyAct/cert')),
  },
  /*{
    name: 'Certificado Show',
    exact: true,
    path: '/:id/certificado/pdf',
    canActivate: [OperatorGuard],
    component: lazy(() => import('../MyAct/cert/pdf')),
  },*/
]
