import { contextTypes } from "../types";

const initialState = {
  loading: false,
  error: "",
  context: 0,
};

const contextReducer = (state = initialState, action) => {
  switch (action.type) {
    case contextTypes.SET_CONTEXT_REQUEST:
      return { ...initialState, loading: true };
    case contextTypes.SET_CONTEXT_FAILURE:
      return { ...initialState, error: action.payload.error, loading: false };
    case contextTypes.SET_CONTEXT_SUCCESS:
      return { ...initialState, loading: false, context: action.payload.body };
    default:
      return state;
  }
};

export default contextReducer;
