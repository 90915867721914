import { roleTypes } from '../types'

const initialState = {
  entry: {},
  loading: false,
  error: '',
  roles: [],
}

const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case roleTypes.POST_ROLE_REQUEST:
      return { ...initialState, roles: [...state.roles], loading: true }
    case roleTypes.POST_ROLE_FAILURE:
      return {
        ...initialState,
        roles: [...state.roles],
        error: action.payload.error,
        loading: false,
      }
    case roleTypes.POST_ROLE_SUCCESS:
      return { ...initialState, loading: false }
    case roleTypes.GET_ROLE_REQUEST:
      return { ...initialState, loading: true }
    case roleTypes.GET_ROLE_FAILURE:
      return { ...initialState, error: action.payload.error, loading: false }
    case roleTypes.GET_ROLE_SUCCESS:
      return { ...initialState, roles: action.payload.body, loading: false }
    case roleTypes.PATCH_ROLE_REQUEST:
      return { ...initialState, roles: [...state.roles], loading: true }
    // case roleTypes.POST_ROLE_FAILURE:
    //   return {
    //     ...initialState,
    //     roles: [...state.roles],
    //     error: action.payload.error,
    //     loading: false,
    //   }
    // case roleTypes.POST_ROLE_SUCCESS:
    //   return { ...initialState, loading: false }
    default:
      return state
  }
}

export default roleReducer
