import { warehouseTypes as types } from '../types'

const initialState = {
  entry: {
    name: '',
    address: '',
    phone: '',
    email: '',
    manager: {
      name: '',
      lastName: '',
    },
    office: {
      name: '',
      address: '',
      phone: '',
    },
  },
  products: {
    items: [],
  },
  supplies: {
    items: [],
  },
  movements: {
    items: [],
  },
  productRequests: {
    current: {},
    all: {},
  },
  loading: false,
  errors: '',
  all: [],
}

const warehouseReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case types.DEFAULT_ACTION:
      return { ...state, loading: true }
    case types.GET_WAREHOUSE_PRODUCTS_SUCCESS:
      return { ...state, products: action.payload, loading: false }
    case types.GET_WAREHOUSE_SUPPLIES_SUCCESS:
      return { ...state, supplies: action.payload, loading: false }
    case types.GET_WAREHOUSE_PRODUCT_REQUESTS_SUCCESS:
      return {
        ...state,
        productRequests: {
          ...state.productRequests,
          all: action.payload,
        },
        loading: false,
      }
    case types.GET_WAREHOUSE_MOVEMENTS_SUCCESS:
      return { ...state, movements: action.payload, loading: false }
    case types.POST_WAREHOUSE_REQUEST:
      return { ...state, all: [...state.all], loading: true }
    case types.POST_WAREHOUSE_FAILURE:
      return {
        ...state,
        all: [...state.all],
        errors: action.payload,
        loading: false,
      }
    case types.POST_WAREHOUSE_SUCCESS:
      return { ...state, loading: false }
    case types.GET_WAREHOUSES_REQUEST:
      return { ...state, loading: true }
    case types.GET_WAREHOUSES_FAILURE:
      return { ...initialState, error: action.payload, loading: false }
    case types.GET_WAREHOUSES_SUCCESS:
      return { ...state, all: action.payload.body, loading: false }
    case types.GET_WAREHOUSE_SUCCESS:
      return { ...state, entry: action.payload, loading: false }
    case types.PATCH_WAREHOUSE_REQUEST:
      return { ...state, all: [...state.all], loading: true }
    case types.PATCH_WAREHOUSE_FAILURE:
      return {
        ...state,
        all: [...state.all],
        error: action.payload,
        loading: false,
      }
    case types.PATCH_WAREHOUSE_SUCCESS:
      return { ...state, loading: false }
    case types.GET_WAREHOUSES_ENABLE_REQUEST:
      return { ...state, all: [...state.all], loading: true }
    case types.GET_WAREHOUSES_ENABLE_FAILURE:
      return {
        ...state,
        all: [...state.all],
        error: action.payload,
        loading: false,
      }
    case types.GET_WAREHOUSES_DISABLE_REQUEST:
      return { ...state, all: [...state.all], loading: true }
    case types.GET_WAREHOUSES_DISABLE_FAILURE:
      return {
        ...state,
        all: [...state.all],
        error: action.payload,
        loading: false,
      }
    case types.GET_WAREHOUSES_ENABLE_SUCCESS:
    case types.GET_WAREHOUSES_DISABLE_SUCCESS:
    case types.POST_WAREHOUSE_PRODUCT_SUCCESS:
    case types.POST_WAREHOUSE_SUPPLIES_SUCCESS:
    case types.POST_WAREHOUSE_PRODUCT_REQUESTS_SUCCESS:
      return { ...state, loading: false }
    case types.POST_WAREHOUSE_PRODUCT_FAILURE:
    case types.POST_WAREHOUSE_SUPPLY_FAILURE:
    case types.GET_WAREHOUSE_PRODUCTS_FAILURE:
    case types.GET_WAREHOUSE_SUPPLIES_FAILURE:
    case types.GET_WAREHOUSE_MOVEMENTS_FAILURE:
    case types.POST_WAREHOUSE_PRODUCT_REQUESTS_FAILURE:
    case types.GET_WAREHOUSE_PRODUCT_REQUESTS_FAILURE:
      return { ...state, error: action.payload, loading: false }
    case types.RESET_WAREHOUSE:
      return {
        ...initialState,
      }
    case types.RESET_WAREHOUSE_PRODUCT_REQUESTS:
      return {
        ...state,
        productRequests: {
          current: {},
          all: {},
          errors: '',
        },
      }
    default:
      return state
  }
}

export default warehouseReducer
