const suppliesTypes = {
  GET_REQUEST: 'GET_SUPPLIES_REQUEST',
  GET_SUCCESS: 'GET_SUPPLIES_SUCCESS',
  GET_FAILURE: 'GET_SUPPLIES_FAILURE',
  POST_REQUEST: 'POST_SUPPLIES_REQUEST',
  POST_SUCCESS: 'POST_SUPPLIES_SUCCESS',
  POST_FAILURE: 'POST_SUPPLIES_FAILURE',
  PATCH_REQUEST: 'PATCH_SUPPLIES_REQUEST',
  PATCH_SUCCESS: 'PATCH_SUPPLIES_SUCCESS',
  PATCH_FAILURE: 'PATCH_SUPPLIES_FAILURE',
  PATCH_ENABLE_REQUEST: 'PATCH_SUPPLIES_ENABLE_REQUEST',
  PATCH_ENABLE_SUCCESS: 'PATCH_SUPPLIES_ENABLE_SUCCESS',
  PATCH_ENABLE_FAILURE: 'PATCH_SUPPLIES_ENABLE_FAILURE',
  PATCH_DISABLE_REQUEST: 'PATCH_SUPPLIES_DISABLE_REQUEST',
  PATCH_DISABLE_SUCCESS: 'PATCH_SUPPLIES_DISABLE_SUCCESS',
  PATCH_DISABLE_FAILURE: 'PATCH_SUPPLIES_DISABLE_FAILURE',
  RESET_LIST: 'RESET_LIST',
}

export { suppliesTypes }
