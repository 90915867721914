const officeTypes = {
  POST_OFFICE_REQUEST: 'POST_OFFICE_REQUEST',
  POST_OFFICE_SUCCESS: 'POST_OFFICE_SUCCESS',
  POST_OFFICE_FAILURE: 'POST_OFFICE_FAILURE',
  GET_OFFICES_REQUEST: 'GET_OFFICES_REQUEST',
  GET_OFFICES_FAILURE: 'GET_OFFICES_FAILURE',
  GET_OFFICES_SUCCESS: 'GET_OFFICES_SUCCESS',
  PATCH_OFFICE_REQUEST: 'PATCH_OFFICE_REQUEST',
  PATCH_OFFICE_FAILURE: 'PATCH_OFFICE_FAILURE',
  PATCH_OFFICE_SUCCESS: 'PATCH_OFFICE_SUCCESS',
  GET_OFFICES_ENABLE_REQUEST: 'GET_OFFICES_ENABLE_REQUEST',
  GET_OFFICES_ENABLE_FAILURE: 'GET_OFFICES_ENABLE_FAILURE',
  GET_OFFICES_ENABLE_SUCCESS: 'GET_OFFICES_ENABLE_SUCCESS',
  GET_OFFICES_DISABLE_REQUEST: 'GET_OFFICES_DISABLE_REQUEST',
  GET_OFFICES_DISABLE_FAILURE: 'GET_OFFICES_DISABLE_FAILURE',
  GET_OFFICES_DISABLE_SUCCESS: 'GET_OFFICES_DISABLE_SUCCESS',
}

export { officeTypes }
