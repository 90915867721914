import { permissionsTypes } from "../types";

const initialState = {
  loading: false,
  error: "",
  permissions: [],
};

const permissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case permissionsTypes.GET_PERMISSIONS_REQUEST:
      return { ...initialState, loading: true };
    case permissionsTypes.GET_PERMISSIONS_FAILURE:
      return { ...initialState, error: action.payload.error, loading: false };
    case permissionsTypes.GET_PERMISSIONS_SUCCESS:
      return {
        ...initialState,
        loading: false,
        permissions: action.payload.body,
      };
    default:
      return state;
  }
};

export default permissionsReducer;
