const userTypes = {
  POST_USER_REQUEST: 'POST_USER_REQUEST',
  POST_USER_SUCCESS: 'POST_USER_SUCCESS',
  POST_USER_FAILURE: 'POST_USER_FAILURE',
  GET_USERS_REQUEST: 'GET_USERS_REQUEST',
  GET_USERS_FAILURE: 'GET_USERS_FAILURE',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  PATCH_USER_REQUEST: 'PATCH_USER_REQUEST',
  PATCH_USER_FAILURE: 'PATCH_USER_FAILURE',
  PATCH_USER_SUCCESS: 'PATCH_USER_SUCCESS',
  GET_USERS_ENABLE_REQUEST: 'GET_USERS_ENABLE_REQUEST',
  GET_USERS_ENABLE_FAILURE: 'GET_USERS_ENABLE_FAILURE',
  GET_USERS_ENABLE_SUCCESS: 'GET_USERS_ENABLE_SUCCESS',
  GET_USERS_DISABLE_REQUEST: 'GET_USERS_DISABLE_REQUEST',
  GET_USERS_DISABLE_FAILURE: 'GET_USERS_DISABLE_FAILURE',
  GET_USERS_DISABLE_SUCCESS: 'GET_USERS_DISABLE_SUCCESS',
  RESET_LIST: 'RESET_LIST',
}

export { userTypes }
