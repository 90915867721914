import { officeTypes as types } from '../types'

const initialState = {
  entry: {},
  loading: false,
  error: '',
  all: [],
}

const officeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.POST_OFFICE_REQUEST:
      return { ...state, all: [...state.all], loading: true }
    case types.POST_OFFICE_FAILURE:
      return {
        ...state,
        all: [...state.all],
        error: action.payload.error,
        loading: false,
      }
    case types.POST_OFFICE_SUCCESS:
      return { ...state, loading: false }
    case types.GET_OFFICES_REQUEST:
      return { ...state, loading: true }
    case types.GET_OFFICE_FAILURE:
      return { ...initialState, error: action.payload.error, loading: false }
    case types.GET_OFFICES_SUCCESS:
      return { ...state, all: action.payload.body, loading: false }
    case types.PATCH_OFFICE_REQUEST:
      return { ...state, all: [...state.all], loading: true }
    case types.PATCH_OFFICE_FAILURE:
      return {
        ...state,
        all: [...state.all],
        error: action.payload.error,
        loading: false,
      }
    case types.PATCH_OFFICE_SUCCESS:
      return { ...state, loading: false }
    case types.GET_OFFICES_ENABLE_REQUEST:
      return { ...state, all: [...state.all], loading: true }
    case types.GET_OFFICES_ENABLE_FAILURE:
      return {
        ...state,
        all: [...state.all],
        error: action.payload.error,
        loading: false,
      }
    case types.GET_OFFICES_ENABLE_SUCCESS:
      return { ...state, loading: false }
    case types.GET_OFFICES_DISABLE_REQUEST:
      return { ...state, all: [...state.all], loading: true }
    case types.GET_OFFICES_DISABLE_FAILURE:
      return {
        ...state,
        all: [...state.all],
        error: action.payload.error,
        loading: false,
      }
    case types.GET_OFFICES_DISABLE_SUCCESS:
      return { ...state, loading: false }
    default:
      return state
  }
}

export default officeReducer
