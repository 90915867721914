import superagent from "superagent";

const API_WEB = process.env.REACT_APP_API;

const request = {
  del: (url) => superagent.del(`${url}`),
  get: (url) => superagent.get(`${url}`),
  patch: (url, body) => superagent.patch(`${url}`, body),
  put: (url, body) => superagent.put(`${url}`, body),
  post: (url, body) => superagent.post(`${url}`, body),
};

export { request, API_WEB };
