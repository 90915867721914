import { lazy } from 'react-router-guard'
import { HeadGuard } from 'helpers'

import managementRoutes from './managementRoutes'
//import agentRoutes from './agentRoutes'
//import telemarketingRoutes from './telemarketingRoutes'

const PrivateModule = [
  {
    name: 'Inicio',
    path: '/',
    exact: true,
    component: lazy(() => import('../home')),
  },
  {
    name: 'Editar perfil',
    path: '/editar-perfil',
    exact: true,
    component: lazy(() => import('../currentUser/editProfile')),
  },
  {
    name: 'Cambiar contraseña',
    path: '/cambiar-contrasena',
    exact: true,
    component: lazy(() => import('../currentUser/changePassword')),
  },
  {
    name: 'Cambiar firma',
    path: '/cambiar-firma',
    exact: true,
    component: lazy(() => import('../currentUser/changeSignature')),
  },
  {
    name: '/tecnicos-operarios',
    path: '/tecnicos-operarios',
    exact: true,
    canActivate: [HeadGuard],
    component: lazy(() => import('../operatorTec')),
  },
  {
    name: 'Tecnicos Show',
    exact: true,
    path: '/tecnicos-operarios/:id',
    canActivate: [HeadGuard],
    component: lazy(() => import('../operatorTec/show')),
  },
  /*{
    name: 'Detalle de Referidos',
    path: '/referidos/:id',
    exact: true,
    component: lazy(() => import('../Referral/Show')),
  },*/
  ...managementRoutes,
  //...agentRoutes,
  //...telemarketingRoutes,
]

export default PrivateModule
