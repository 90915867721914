const categoriesTypes = {
  GET_CATEGORIES_REQUEST: "GET_CATEGORIES_REQUEST",
  GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
  GET_CATEGORIES_FAILURE: "GET_CATEGORIES_FAILURE",
  POST_CATEGORIES_REQUEST: "POST_CATEGORIES_REQUEST",
  POST_CATEGORIES_SUCCESS: "POST_CATEGORIES_SUCCESS",
  POST_CATEGORIES_FAILURE: "POST_CATEGORIES_FAILURE",
  PATCH_CATEGORIES_REQUEST: "PATCH_CATEGORIES_REQUEST",
  PATCH_CATEGORIES_SUCCESS: "PATCH_CATEGORIES_SUCCESS",
  PATCH_CATEGORIES_FAILURE: "PATCH_CATEGORIES_FAILURE",
};

export { categoriesTypes };
