const warehouseTypes = {
  DEFAULT_ACTION: 'WAREHOUSE_DEFAULT_ACTION',
  POST_WAREHOUSE_REQUEST: 'POST_WAREHOUSE_REQUEST',
  POST_WAREHOUSE_SUCCESS: 'POST_WAREHOUSE_SUCCESS',
  POST_WAREHOUSE_FAILURE: 'POST_WAREHOUSE_FAILURE',
  GET_WAREHOUSES_REQUEST: 'GET_WAREHOUSES_REQUEST',
  GET_WAREHOUSES_FAILURE: 'GET_WAREHOUSES_FAILURE',
  GET_WAREHOUSES_SUCCESS: 'GET_WAREHOUSES_SUCCESS',
  PATCH_WAREHOUSE_REQUEST: 'PATCH_WAREHOUSE_REQUEST',
  PATCH_WAREHOUSE_FAILURE: 'PATCH_WAREHOUSE_FAILURE',
  PATCH_WAREHOUSE_SUCCESS: 'PATCH_WAREHOUSE_SUCCESS',
  GET_WAREHOUSE_REQUEST: 'GET_WAREHOUSE_REQUEST',
  GET_WAREHOUSE_SUCCESS: 'GET_WAREHOUSE_SUCCESS',
  RESET_WAREHOUSE: 'RESET_WAREHOUSE',

  // Constants for Product assignment from general manager.
  POST_WAREHOUSE_PRODUCT_SUCCESS: 'POST_WAREHOUSE_PRODUCT_SUCCESS',
  POST_WAREHOUSE_PRODUCT_FAILURE: 'POST_WAREHOUSE_PRODUCT_FAILURE',
  GET_WAREHOUSE_PRODUCTS_SUCCESS: 'GET_WAREHOUSES_PRODUCT_SUCCESS',
  GET_WAREHOUSE_PRODUCTS_FAILURE: 'GET_WAREHOUSES_PRODUCT_FAILURE',

  // Constants for Supply assignment from general manager.
  POST_WAREHOUSE_SUPPLY_SUCCESS: 'POST_WAREHOUSE_SUPPLY_SUCCESS',
  POST_WAREHOUSE_SUPPLY_FAILURE: 'POST_WAREHOUSE_SUPPLY_FAILURE',
  GET_WAREHOUSE_SUPPLIES_SUCCESS: 'GET_WAREHOUSES_SUPPLIES_SUCCESS',
  GET_WAREHOUSE_SUPPLIES_FAILURE: 'GET_WAREHOUSES_SUPPLIES_FAILURE',

  // Constants for warehouse movements of products and supplies.
  GET_WAREHOUSE_MOVEMENTS_SUCCESS: 'GET_WAREHOUSE_MOVEMENTS_SUCCESS',
  GET_WAREHOUSE_MOVEMENTS_FAILURE: 'GET_WAREHOUSE_MOVEMENTS_FAILURE',

  // Constants for Product requests from sales people.
  POST_WAREHOUSE_PRODUCT_REQUESTS_SUCCESS:
    'POST_WAREHOUSE_PRODUCT_REQUESTS_SUCCESS',
  POST_WAREHOUSE_PRODUCT_REQUESTS_FAILURE:
    'POST_WAREHOUSE_PRODUCT_REQUESTS_FAILURE',
  GET_WAREHOUSE_PRODUCT_REQUESTS_SUCCESS:
    'GET_WAREHOUSE_PRODUCT_REQUESTS_SUCCESS',
  GET_WAREHOUSE_PRODUCT_REQUESTS_FAILURE:
    'GET_WAREHOUSE_PRODUCT_REQUESTS_FAILURE',
  RESET_WAREHOUSE_PRODUCT_REQUESTS: 'RESET_WAREHOUSE_PRODUCT_REQUESTS',
}

export { warehouseTypes }
