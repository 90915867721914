import { communeTypes } from "../types";

const initialState = {
  loading: false,
  error: "",
  communes: [],
};

const communesReducer = (state = initialState, action) => {
  switch (action.type) {
    case communeTypes.GET_COMMUNE_REQUEST:
      return { ...initialState, loading: true };
    case communeTypes.GET_COMMUNE_FAILURE:
      return { ...initialState, error: action.payload.error, loading: false };
    case communeTypes.GET_COMMUNE_SUCCESS:
      return {
        ...initialState,
        loading: false,
        communes: action.payload.body,
      };
    default:
      return state;
  }
};

export default communesReducer;
