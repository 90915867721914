import { categoriesTypes } from "../types";

const initialState = {
  loading: false,
  error: "",
  categories: [],
};

const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case categoriesTypes.GET_CATEGORIES_REQUEST:
      return { ...state, loading: true };
    case categoriesTypes.GET_CATEGORIES_FAILURE:
      return { ...state, error: action.payload.error, loading: false };
    case categoriesTypes.GET_CATEGORIES_SUCCESS:
      return { ...state, loading: false, categories: action.payload.body };
    case categoriesTypes.POST_CATEGORIES_REQUEST:
      return { ...state, categories: [...state.categories], loading: true };
    case categoriesTypes.POST_CATEGORIES_FAILURE:
      return { ...state, categories: [...state.categories], error: action.payload.error, loading: false };
    case categoriesTypes.POST_CATEGORIES_SUCCESS:
      return { ...state, loading: false };
    case categoriesTypes.PATCH_CATEGORIES_REQUEST:
      return { ...state, categories: [...state.categories], loading: true };
    case categoriesTypes.PATCH_CATEGORIES_FAILURE:
      return { ...state, categories: [...state.categories], error: action.payload.error, loading: false };
    case categoriesTypes.PATCH_CATEGORIES_SUCCESS:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default categoriesReducer;
