import { authTypes } from '../types'

const initialState = {
  roles: [],
  permissions: [],
  name: '',
  lastName: '',
  id: '',
  token: '',
  loading: false,
  exp: '',
  error: '',
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.LOGIN_REQUEST:
      return { ...state, loading: true }
    case authTypes.LOGIN_SUCCESS:
      return {
        roles: action.payload.info.roles,
        permissions: action.payload.info.permissions,
        name: action.payload.info.name,
        lastName: action.payload.info.lastName,
        id: action.payload.info.id,
        exp: action.payload.info.exp,
        token: action.payload.token,
        loading: false,
      }
    case authTypes.LOGIN_FAILURE:
      return { ...initialState, error: action.payload.error }
    case authTypes.LOGOUT:
      return { ...initialState }
    case authTypes.UPDATE:
      return {
        roles: action.payload.info.roles,
        permissions: action.payload.info.permissions,
        name: action.payload.info.name,
        lastName: action.payload.info.lastName,
        id: action.payload.info.id,
        exp: action.payload.info.exp,
        token: action.payload.token,
        loading: false,
      }
    default:
      return state
  }
}

export default authReducer
