import { userTypes } from '../types'

const initialState = {
  entry: {},
  loading: false,
  error: '',
  users: [],
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userTypes.POST_USER_REQUEST:
      return { ...state, users: [...state.users], loading: true }
    case userTypes.POST_USER_FAILURE:
      return {
        ...state,
        users: [...state.users],
        error: action.payload.error,
        loading: false,
      }
    case userTypes.POST_USER_SUCCESS:
      return { ...state, loading: false }
    case userTypes.GET_USERS_REQUEST:
      return { ...state, loading: true }
    case userTypes.GET_USER_FAILURE:
      return { ...initialState, error: action.payload.error, loading: false }
    case userTypes.GET_USERS_SUCCESS:
      return { ...state, users: action.payload.body, loading: false }
    case userTypes.PATCH_USER_REQUEST:
      return { ...state, users: [...state.users], loading: true }
    case userTypes.PATCH_USER_FAILURE:
      return {
        ...state,
        users: [...state.users],
        error: action.payload.error,
        loading: false,
      }
    case userTypes.PATCH_USER_SUCCESS:
      return { ...state, loading: false }
    case userTypes.GET_USERS_ENABLE_REQUEST:
      return { ...state, users: [...state.users], loading: true }
    case userTypes.GET_USERS_ENABLE_FAILURE:
      return {
        ...state,
        users: [...state.users],
        error: action.payload.error,
        loading: false,
      }
    case userTypes.GET_USERS_ENABLE_SUCCESS:
      return { ...state, loading: false }
    case userTypes.GET_USERS_DISABLE_REQUEST:
      return { ...state, users: [...state.users], loading: true }
    case userTypes.GET_USERS_DISABLE_FAILURE:
      return {
        ...state,
        users: [...state.users],
        error: action.payload.error,
        loading: false,
      }
    case userTypes.GET_USERS_DISABLE_SUCCESS:
      return { ...state, loading: false }
    case userTypes.RESET_LIST:
      return {
        entry: {},
        loading: false,
        error: '',
        users: [],
      }
    default:
      return state
  }
}

export default userReducer
