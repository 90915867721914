import { productsTypes } from '../types'

const initialState = {
  loading: false,
  error: '',
  products: [],
}

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case productsTypes.GET_PRODUCTS_REQUEST:
      return { ...state, loading: true }
    case productsTypes.GET_PRODUCTS_FAILURE:
      return { ...state, error: action.payload.error, loading: false }
    case productsTypes.GET_PRODUCTS_SUCCESS:
      return { ...state, loading: false, products: action.payload.body }
    case productsTypes.POST_PRODUCTS_REQUEST:
      return { ...state, products: [...state.products], loading: true }
    case productsTypes.POST_PRODUCTS_FAILURE:
      return {
        ...state,
        products: [...state.products],
        error: action.payload.error,
        loading: false,
      }
    case productsTypes.POST_PRODUCTSS_SUCCESS:
      return { ...state, loading: false }
    case productsTypes.PATCH_PRODUCTS_REQUEST:
      return { ...state, products: [...state.products], loading: true }
    case productsTypes.PATCH_PRODUCTS_FAILURE:
      return {
        ...state,
        products: [...state.products],
        error: action.payload.error,
        loading: false,
      }
    case productsTypes.PATCH_PRODUCTS_SUCCESS:
      return { ...state, loading: false }
    case productsTypes.PATCH_PRODUCTS_ENABLE_REQUEST:
      return { ...state, products: [...state.products], loading: true }
    case productsTypes.PATCH_PRODUCTS_ENABLE_FAILURE:
      return {
        ...state,
        products: [...state.products],
        error: action.payload.error,
        loading: false,
      }
    case productsTypes.PATCH_PRODUCTS_ENABLE_SUCCESS:
      return { ...state, loading: false }
    case productsTypes.PATCH_PRODUCTS_DISABLE_REQUEST:
      return { ...state, products: [...state.products], loading: true }
    case productsTypes.PATCH_PRODUCTS_DISABLE_FAILURE:
      return {
        ...state,
        products: [...state.products],
        error: action.payload.error,
        loading: false,
      }
    case productsTypes.PATCH_PRODUCTS_DISABLE_SUCCESS:
      return { ...state, loading: false }
    case productsTypes.RESET_LIST:
      return { loading: false, error: '', products: [] }
    default:
      return state
  }
}

export default productsReducer
